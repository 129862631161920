<template>
  <div class="container">
    <login />
  </div>
</template>
<script>
import login from '@/components/web/session/login.vue'

export default {
  name: 'sessionLogin',
  components: {
    login
  },
}
</script>
